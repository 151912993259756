@tailwind base;
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  width: thin;
  border-radius: 5px;
  background: #BDBDBD;
}

::-webkit-scrollbar-thumb:hover {
  background: #6E6E6E;
}

::-webkit-scrollbar-corner {
  visibility: hidden;
}
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Pacifico;
  src: url("../fonts/Pacifico/Pacifico-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
